/**
 * High level router-NOTUSED.
 *
 * Note: It's recommended to compose related routes in internal router-NOTUSED
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {lazy} from "react";
import {Redirect, Switch, Route, useRouteMatch} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

const WDashboardpage = lazy(() =>
    import("./pages/home/WDashboard/WDashboard")
);

const SafetyInductionpage = lazy(() =>
    import("./pages/home/Safety/Induction")
);

export function Routes() {
    let { path, url } = useRouteMatch();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );


  return (
    <Switch>
        <Route path="/WDashboard" component={WDashboardpage} />
        <Route path={`/Safety/Induction/:inspectionId`} component={SafetyInductionpage} />
      {!isAuthorized ? (
        /*Render auth-NOTUSED page when user at `/auth-NOTUSED` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}


      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth-NOTUSED` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
