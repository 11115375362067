import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import textEditor from "../components/TextEditor/TextEditor";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const Scheduler = lazy(() =>
    import("./pages/home/System/Scheduler")
);

const Emailer = lazy(() =>
    import("./pages/home/System/Email")
);

const Screens = lazy(() =>
    import("./pages/home/WDashboard/Screens")
);

const Vehicles = lazy(() =>
    import("./pages/home/Vehicles")
);

const HelpDesk = lazy(() =>
    import("./pages/home/HelpDesk/HelpDesk")
);

const ScheduleHead = lazy(() =>
    import("./pages/home/ScheduleHead")
);

const Craft = lazy(() =>
    import("./pages/home/Craft")
);

const FlexInventoryEditor = lazy(() =>
    import("../flex/pages/BulkInventoryEditor/BulkInventoryEditor")
);

const Safety = lazy(() =>
    import("./pages/home/Safety")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/inventory" component={FlexInventoryEditor} />
        <Route path="/schedulehead" component={ScheduleHead} />
        <Route path="/craft" component={Craft} />
        <Route path="/texteditor" component={textEditor} />
        <Route path="/HelpDesk" component={HelpDesk} />
        <Route path="/Vehicles" component={Vehicles} />
        <Route path="/Screens" component={Screens} />
        <Route path="/System/Scheduler" component={Scheduler} />
        <Route path="/System/Emailer" component={Emailer} />
        <Route path="/Safety" component={Safety} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
